/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        function resetPageTopPadding() {
          var headerHeight;
          if ($(window).width() < 1200) {
            headerHeight =
              $("#header .navbar-brand").outerHeight(true) +
              $("#header #date-banner").outerHeight(true) -
              $("body").offset().top;
          } else {
            headerHeight = $("#fixed-header").height() - $("body").offset().top;
          }
          $(".page-banner").css("padding-top", headerHeight);
          $(".slider-overlay").css("top", headerHeight);
          $(".slider-overlay").css(
            "height",
            "calc(100% - " + headerHeight + "px)"
          );
        }

        /**
         *
         * @param {Object} target Targeted element
         * @param {string} property CSS property of element to be targeted
         * @param {float} minValue Minimum property value
         * @param {float} maxValue Maximum property value
         * @param {float} transitionDistance Distance scrolled to complete transition (px)
         * @param {float} offset Distance off window top that transition starts (px)
         */

        function scrollAnimation(
          target,
          property,
          initialValue,
          finalValue,
          transitionDistance,
          offset
        ) {
          var deltaValue = finalValue - initialValue;
          var scrollTop = $(window).scrollTop() - offset;
          var value;

          if (scrollTop <= 0) {
            value = initialValue;
          } else if (scrollTop >= transitionDistance) {
            value = finalValue;
          } else {
            value =
              initialValue + (scrollTop / transitionDistance) * deltaValue;
          }
          target.css(property, value);
        }

        function resizeHeader() {
          var headerBrand = $("#header .brand");
          var headerBanner = $("#header #date-banner");
          var transitionDistance = 200;

          scrollAnimation(
            headerBrand,
            "width",
            200,
            130,
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBrand,
            "margin-top",
            20,
            8,
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBrand,
            "margin-bottom",
            20,
            8,
            transitionDistance,
            200
          );

          scrollAnimation(
            headerBanner,
            "margin-top",
            0,
            -headerBanner.height(),
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBanner.find("span"),
            "opacity",
            1,
            0,
            transitionDistance,
            160
          );
        }

        resizeHeader();
        resetPageTopPadding();

        $(window).on("scroll", function () {
          resizeHeader();
          resetPageTopPadding();
        });

        $(window).on("resize", function () {
          resetPageTopPadding();
        });

        if (typeof owlCarousel === "function") {
          $(".owl-carousel").owlCarousel({
            loop: true,
            items: 1,
            autoplay: true,
            autoplayHoverPause: true,
            nav: true,
            dots: true,
            //animateOut: 'fadeOut'
          });
        }

        var eventDate = $("#countdown-clock").data("event-date");
        var languageCode = $("html").attr("lang");
        if (languageCode === "fr-FR") {
          $("#countdown-clock").countdown(eventDate, function (event) {
            $(this).html(
              event.strftime(
                '<div class="days"><span class="number">%D</span><span class="countdown-label">JOURS</span></div>' +
                  '<div class="hours"><span class="number">%H</span><span class="countdown-label">HEURES</span></div>' +
                  '<div class="minutes"><span class="number">%M</span><span class="countdown-label">MINUTES</span></div>' +
                  '<div class="seconds"><span class="number">%S</span><span class="countdown-label">SECONDES</span></div>'
              )
            );
          });
        } else {
          $("#countdown-clock").countdown(eventDate, function (event) {
            $(this).html(
              event.strftime(
                '<div class="days"><span class="number">%D</span><span class="countdown-label">DAYS</span></div>' +
                  '<div class="hours"><span class="number">%H</span><span class="countdown-label">HOURS</span></div>' +
                  '<div class="minutes"><span class="number">%M</span><span class="countdown-label">MINUTES</span></div>' +
                  '<div class="seconds"><span class="number">%S</span><span class="countdown-label">SECONDS</span></div>'
              )
            );
          });
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        function resetPageTopPadding() {
          var headerHeight;
          if ($(window).width() < 1200) {
            headerHeight =
              $("#header .navbar-brand").outerHeight(true) +
              $("#header #date-banner").outerHeight(true) -
              $("body").offset().top;
          } else {
            headerHeight = $("#fixed-header").height() - $("body").offset().top;
          }
          $(".page-banner").css("padding-top", headerHeight);
          $(".slider-overlay").css("top", headerHeight);
          $(".slider-overlay").css(
            "height",
            "calc(100% - " + headerHeight + "px)"
          );
        }

        /**
         *
         * @param {Object} target Targeted element
         * @param {string} property CSS property of element to be targeted
         * @param {float} minValue Minimum property value
         * @param {float} maxValue Maximum property value
         * @param {float} transitionDistance Distance scrolled to complete transition (px)
         * @param {float} offset Distance off window top that transition starts (px)
         */

        function scrollAnimation(
          target,
          property,
          initialValue,
          finalValue,
          transitionDistance,
          offset
        ) {
          var deltaValue = finalValue - initialValue;
          var scrollTop = $(window).scrollTop() - offset;
          var value;

          if (scrollTop <= 0) {
            value = initialValue;
          } else if (scrollTop >= transitionDistance) {
            value = finalValue;
          } else {
            value =
              initialValue + (scrollTop / transitionDistance) * deltaValue;
          }
          target.css(property, value);
        }

        function resizeHeader() {
          var headerBrand = $("#header .brand");
          var headerBanner = $("#header #date-banner");
          var transitionDistance = 200;

          scrollAnimation(
            headerBrand,
            "width",
            200,
            130,
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBrand,
            "margin-top",
            20,
            8,
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBrand,
            "margin-bottom",
            20,
            8,
            transitionDistance,
            200
          );

          scrollAnimation(
            headerBanner,
            "margin-top",
            0,
            -headerBanner.height(),
            transitionDistance,
            200
          );
          scrollAnimation(
            headerBanner.find("span"),
            "opacity",
            1,
            0,
            transitionDistance,
            160
          );
        }

        resizeHeader();
        resetPageTopPadding();

        $(window).on("scroll", function () {
          resizeHeader();
          resetPageTopPadding();
        });

        $(window).on("resize", function () {
          resetPageTopPadding();
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // Program
    program: {
      init: function () {},
      finalize: function () {},
    },
    faculty: {
      init: function () {},
      finalize: function () {},
    },
    symposium_facts: {
      init: function () {},
      finalize: function () {},
    },
    symposium_venue: {
      init: function () {},
      finalize: function () {},
    },
    press: {
      init: function () {},
      finalize: function () {},
    },
    contact_us: {
      init: function () {},
      finalize: function () {},
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    registration_form: {
      init: function () {
        console.log("ajaxurl", ajaxurl);

        function generateFormTokenNew(amount) {
          console.log("amount", amount);
          const data = {
            action: "payment",
            amount: amount,
          };

          $.ajax({
            type: "POST",
            url: ajaxurl,
            data: data,
          }).done(function (responseJSON) {
            const response = JSON.parse(responseJSON);
            // const formToken = response.answer.formToken;
            console.log("response", response);
            // $('#stripe-form').attr("kr-form-token", formToken);
          });
        }

        // function generateFormToken(amount) {
        //   console.log('amount', amount);
        //   var settings = {
        //     "url": "https://api.systempay.fr/api-payment/V4/Charge/CreatePayment",
        //     "method": "POST",
        //     "timeout": 0,
        //     "crossDomain": true,
        //     "headers": {
        //       "Content-Type": "application/json",
        //       "Authorization": "Basic Nzg4OTE1Njc6dGVzdHBhc3N3b3JkX2NieVk1ZlBMMUdOZTk1cVJ6eUxhVEo2MzBLd0RZVG5ZbXdTTUNyRjNaN1V3ag==",
        //       'Access-Control-Allow-Origin': '*',
        //       "Access-Control-Allow-Credentials": "true",
        //       "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        //       "Access-Control-Allow-Headers": "Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Content-Type"
        //     },
        //     "data": JSON.stringify({"amount":amount,"currency":"EUR","orderId":"myOrderId-999999","customer":{"email":"sample@example.com"}}),
        //   };

        //   $.ajax(settings).done(function (response) {
        //     console.log('response', response);
        //     const formToken = "25Z5GFcNckTta32zMEKCn_Sg20CeyJhbW91bnQiOjk5MCwiY3VycmVuY3kiOiJFVVIiLCJtb2RlIjoiVEVTVCIsInZlcnNpb24iOjMsIm9yZGVySWQiOiJteU9yZGVySWQtOTk5OTk5Iiwic2hvcE5hbWUiOiJGT09UIFNVUkdFUlkgSU5TVElUVVRFIiwiYnJhbmRQcmlvcml0eSI6WyJDQiIsIkUtQ0FSVEVCTEVVRSIsIlZJU0EiLCJWSVNBX0RFQklUIiwiTUFTVEVSQ0FSRCIsIk1BU1RFUkNBUkRfREVCSVQiLCJWSVNBX0VMRUNUUk9OIiwiTUFFU1RSTyJdLCJjYXRlZ29yaWVzIjp7ImRlYml0Q3JlZGl0Q2FyZHMiOnsiYXBwSWQiOiJjYXJkcyIsInBhcmFtIjpbIk1BRVNUUk8iLCJFLUNBUlRFQkxFVUUiLCJNQVNURVJDQVJEX0RFQklUIiwiTUFTVEVSQ0FSRCIsIlZJU0EiLCJWSVNBX0VMRUNUUk9OIiwiVklTQV9ERUJJVCIsIkNCIl19fSwiY2FyZHMiOnsiTUFFU1RSTyI6eyJmaWVsZHMiOnsic2VjdXJpdHlDb2RlIjp7InJlcXVpcmVkIjpmYWxzZX19LCJjb3B5RnJvbSI6ImNhcmRzLkRFRkFVTFQifSwiRS1DQVJURUJMRVVFIjp7ImNvcHlGcm9tIjoiY2FyZHMuREVGQVVMVCJ9LCJNQVNURVJDQVJEX0RFQklUIjp7ImNvcHlGcm9tIjoiY2FyZHMuREVGQVVMVCJ9LCJNQVNURVJDQVJEIjp7ImNvcHlGcm9tIjoiY2FyZHMuREVGQVVMVCJ9LCJWSVNBIjp7ImNvcHlGcm9tIjoiY2FyZHMuREVGQVVMVCJ9LCJWSVNBX0VMRUNUUk9OIjp7ImZpZWxkcyI6eyJzZWN1cml0eUNvZGUiOnsicmVxdWlyZWQiOmZhbHNlfX0sImNvcHlGcm9tIjoiY2FyZHMuREVGQVVMVCJ9LCJERUZBVUxUIjp7ImZpZWxkcyI6eyJwYW4iOnsibWluTGVuZ3RoIjoxMCwibWF4TGVuZ3RoIjoxOSwidmFsaWRhdG9ycyI6WyJOVU1FUklDIiwiTFVITiJdLCJyZXF1aXJlZCI6dHJ1ZSwic2Vuc2l0aXZlIjp0cnVlLCJoaWRkZW4iOmZhbHNlLCJjbGVhck9uRXJyb3IiOmZhbHNlfSwiZXhwaXJ5RGF0ZSI6eyJyZXF1aXJlZCI6dHJ1ZSwic2Vuc2l0aXZlIjp0cnVlLCJoaWRkZW4iOmZhbHNlLCJjbGVhck9uRXJyb3IiOmZhbHNlfSwic2VjdXJpdHlDb2RlIjp7Im1pbkxlbmd0aCI6MywibWF4TGVuZ3RoIjozLCJ2YWxpZGF0b3JzIjpbIk5VTUVSSUMiXSwicmVxdWlyZWQiOnRydWUsInNlbnNpdGl2ZSI6dHJ1ZSwiaGlkZGVuIjpmYWxzZSwiY2xlYXJPbkVycm9yIjp0cnVlfX19LCJDQiI6eyJjb3B5RnJvbSI6ImNhcmRzLkRFRkFVTFQifSwiVklTQV9ERUJJVCI6eyJjb3B5RnJvbSI6ImNhcmRzLkRFRkFVTFQifX0sInBhc3NBY3RpdmF0ZWQiOnRydWUsImFwaVJlc3RWZXJzaW9uIjoiNC4wIn07002";
        //     $('#stripe-form').attr("kr-form-token", formToken);
        //   });
        // }

        function calculatePrice() {
          var coursePrice = 800.0 * $("#acf-course_ticket_quantity").val();
          if (!coursePrice) {
            coursePrice = 0;
          }

          const lunchQuantity = $(
            "#acf-discounted_course_ticket_quantity"
          ).val();

          var lunchPrice = 0;

          var i;

          for (i = 0; i < lunchQuantity; i++) {
            const mealQuantity = $(
              $(".acf-field-meal-options .acf-checkbox-list")[i]
            ).find("input:checked").length;
            lunchPrice += 45.0 * mealQuantity;
          }

          if (!lunchPrice) {
            lunchPrice = 0;
          }

          var dinnerPrice = 80.0 * $("#acf-dinner_ticket_quantity").val();
          if (!dinnerPrice) {
            dinnerPrice = 0;
          }

          var imperialPrice =
            207 *
            $("#acf-imperial_palace_ticket_quantity").val() *
            $(
              "#acf-imperial_ticket_details-imperial_ticket_1-stay_duration"
            ).val();

          if (!imperialPrice) {
            imperialPrice = 0;
          }

          const hotelQuantity = $("#acf-catalpa_hotel_quantity").val();
          var catalpaPrice = 0;

          var j;

          for (j = 1; j <= hotelQuantity; j++) {
            catalpaPrice +=
              150.0 *
              $(
                "#acf-catalpa_ticket_details-catalpa_ticket_" +
                  j +
                  "-stay_duration"
              ).val();
          }

          if (!catalpaPrice) {
            catalpaPrice = 0;
          }

          var registrationPrice =
            350.0 * $("#acf-registration_ticket_quantity").val();
          if (!registrationPrice) {
            registrationPrice = 0;
          }

          var reducedRegistrationPrice =
            100.0 * $("#acf-registration_reduced_ticket_quantity").val();
          if (!reducedRegistrationPrice) {
            reducedRegistrationPrice = 0;
          }

          var totalPrice =
            coursePrice +
            lunchPrice +
            dinnerPrice +
            imperialPrice +
            catalpaPrice +
            registrationPrice +
            reducedRegistrationPrice;
          if (!totalPrice) {
            totalPrice = 0;
          }

          var price =
            'Price: <span class="price">' + totalPrice.toFixed(2) + "€</span>";
          var languageCode = $("html").attr("lang");
          if (languageCode === "fr-FR") {
            price =
              'Prix: <span class="price">' + totalPrice.toFixed(2) + "€</span>";
          }

          $("#total-price").html(price);
          // generateFormTokenNew(totalPrice.toFixed(2) * 100);
        }

        calculatePrice();

        $(".acf-field-meal-options .acf-checkbox-list input").each(function () {
          $(this).change(function () {
            calculatePrice();
          });
        });

        $(".ticket-quantity").each(function () {
          $(this).change(function () {
            calculatePrice();
          });
        });

        $(
          "#acf-imperial_ticket_details-imperial_ticket_1-stay_duration"
        ).change(function () {
          calculatePrice();
        });

        $("#acf-catalpa_ticket_details-catalpa_ticket_1-stay_duration").change(
          function () {
            calculatePrice();
          }
        );

        $("#acf-choose_your_ticket-course-ticket").change(function () {
          if ($("#acf-choose_your_ticket-course-ticket:checked").length > 0) {
            $("#acf-course_ticket_quantity").val("1");
          } else {
            $("#acf-course_ticket_quantity").val("0");
          }
          calculatePrice();
        });

        $("#acf-choose_your_ticket-discounted-course-ticket").change(
          function () {
            if (
              $("#acf-choose_your_ticket-discounted-course-ticket:checked")
                .length > 0
            ) {
              $("#acf-discounted_course_ticket_quantity").val("1");
            } else {
              $("#acf-discounted_course_ticket_quantity").val("0");
            }
            calculatePrice();
          }
        );

        $("#acf-choose_your_ticket-dinner-ticket").change(function () {
          if ($("#acf-choose_your_ticket-dinner-ticket:checked").length > 0) {
            $("#acf-dinner_ticket_quantity").val("1");
          } else {
            $("#acf-dinner_ticket_quantity").val("0");
          }
          calculatePrice();
        });

        $("#acf-choose_your_ticket-imperial-ticket").change(function () {
          if ($("#acf-choose_your_ticket-imperial-ticket:checked").length > 0) {
            $("#acf-imperial_palace_ticket_quantity").val("1");
          } else {
            $("#acf-imperial_palace_ticket_quantity").val("0");
          }
          calculatePrice();
        });

        $("#acf-choose_your_ticket-catalpa-ticket").change(function () {
          if ($("#acf-choose_your_ticket-catalpa-ticket:checked").length > 0) {
            $("#acf-catalpa_hotel_quantity").val("1");
          } else {
            $("#acf-catalpa_hotel_quantity").val("0");
          }
          calculatePrice();
        });

        $("#acf-choose_your_ticket-registration-ticket").change(function () {
          if (
            $("#acf-choose_your_ticket-registration-ticket:checked").length > 0
          ) {
            $("#acf-registration_ticket_quantity").val("1");
          } else {
            $("#acf-registration_ticket_quantity").val("0");
          }
          calculatePrice();
        });

        $("#acf-choose_your_ticket-registration-reduced-ticket").change(
          function () {
            if (
              $("#acf-choose_your_ticket-registration-reduced-ticket:checked")
                .length > 0
            ) {
              $("#acf-registration_reduced_ticket_quantity").val("1");
            } else {
              $("#acf-registration_reduced_ticket_quantity").val("0");
            }
            calculatePrice();
          }
        );

        // var stripe = new Stripe('pk_test_txJ5BwkVx7CZzOFVHcsS5hSH');
        // var elements = stripe.elements();

        var style = {
          base: {
            color: "#212529",
            fontWeight: 500,
            fontFamily: "Lato, sans-serif",
            fontSize: "14px",
            fontSmoothing: "antialiased",

            "::placeholder": {
              color: "#ddd",
            },

            ":-webkit-autofill": {
              color: "#faffbd",
            },
          },
          invalid: {
            color: "#eb1c26",

            "::placeholder": {
              color: "#FFCCA5",
            },
          },
        };

        // Create card number field
        // var cardNumber = elements.create('cardNumber', {style: style});
        // cardNumber.mount('#card-number');

        // cardNumber.addEventListener('change', function(event) {
        //   var displayError = document.getElementById('card-number-errors');
        //   if (event.error) {
        //     displayError.textContent = event.error.message;
        //   } else {
        //     displayError.textContent = '';
        //   }
        // });

        // // Create card expiry field
        // var cardExpiry = elements.create('cardExpiry', {style: style});
        // cardExpiry.mount('#card-expiry');

        // cardExpiry.addEventListener('change', function(event) {
        //   var displayError = document.getElementById('card-expiry-errors');
        //   if (event.error) {
        //     displayError.textContent = event.error.message;
        //   } else {
        //     displayError.textContent = '';
        //   }
        // });

        // // Create cvc field
        // var cardSecurityCode = elements.create('cardCvc', {style: style});
        // cardSecurityCode.mount('#card-security-code');

        // cardSecurityCode.addEventListener('change', function(event) {
        //   var displayError = document.getElementById('card-security-code-errors');
        //   if (event.error) {
        //     displayError.textContent = event.error.message;
        //   } else {
        //     displayError.textContent = '';
        //   }
        // });

        $("body").on("click", ".apply-discount-code", function (e) {
          e.preventDefault();
          // generateFormTokenNew(100);
        });

        // Create a token or display an error when the form is submitted.
        // var form = document.getElementById('stripe-form');
        // form.addEventListener('submit', function(event) {
        //     event.preventDefault();

        //     stripe.createToken(cardNumber).then(function(result) {
        //     if (result.error) {
        //         // Inform the customer that there was an error.
        //         var errorElement = document.getElementById('card-number-errors');
        //         errorElement.textContent = result.error.message;
        //     } else {
        //         console.log(result);
        //         // Send the token to your server.
        //         jQuery('#acf-token_id').val(result.token.id);
        //         jQuery('#form_registration').submit();
        //     }
        //   });
        // });
      },
      finalize: function () {
        $(".af-next-button").click(function () {
          $("#stripe-form").hide();
          $(".spinner").css("display", "block");
          $(".af-page-button").each(function () {
            var button = $(this);
            setTimeout(function () {
              $(".spinner").css("display", "none");
              if (button.data("key") === "payment_page") {
                if (button.hasClass("current")) {
                  $("#stripe-form").show();
                } else {
                  $("#stripe-form").hide();
                }
              }
            }, 3000);
          });
        });

        $(".af-page-button").click(function () {
          $("#stripe-form").hide();
          $(".spinner").css("display", "block");
          $(".af-page-button").each(function () {
            var button = $(this);
            setTimeout(function () {
              $(".spinner").css("display", "none");
              if (button.data("key") === "payment_page") {
                if (button.hasClass("current")) {
                  $("#stripe-form").show();
                } else {
                  $("#stripe-form").hide();
                }
              }
            }, 3000);
          });
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
